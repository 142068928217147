import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import ContactForm from "../components/ContactForm";
import Tech from "../components/Tech";
import Container from "../components/ui/Container";

function SupportMaintenance() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | WEBSITE SUPPORT & MAINTENANCE COMPANY IN INDIA"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies, best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "We specialize in providing website designing and development, and website support and maintenance services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/third-party-header.png"
          alt="Bitdecoders Support-maintenance banner image"
          layout="fullWidth"
        />
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <HStack>
            <Text color="red.500">SUPPORT &amp;</Text>
            <Text color="white">MAINTENANCE</Text>
          </HStack>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="bold">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>

      <Container fluid>
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>
              <div className="mx-10 mt-6 text-lg space-y-6">
                <div>
                  <p>
                    Website maintenance is the process of checking to see
                    whether your website is performing well. It’s about keeping
                    up with security updates, encouraging traffic growth, and
                    making sure Google is happy. In fact, to ensure your website
                    is in a healthy and relevant condition, you need to perform
                    regular check-ups. Not once a year, not twice a year. We’re
                    talking about regular monitoring that will help you keep
                    your business running smoothly and customers enjoying their
                    user experience.
                  </p>
                </div>
                <div>
                  <p>
                    As soon as your website is up and running, it’s time to
                    start taking care of the most basic and crucial web
                    maintenance tasks. We’ve organized these services by
                    separate tasks and by how often you should perform them.
                  </p>
                </div>
              </div>
              <div className="space-y-6 px-10 pt-10 text-lg font-medium">
                <div>
                  <h3 className="text-xl font-bold text-red-500">
                    Weekly Tasks
                  </h3>
                  <div className="pt-4 text-sm">
                    <p>
                      {" "}
                      - Check your website for 404 errors and if there are any,
                      fix or 301 redirect them.
                    </p>
                    <p> - Update your core plugins and website software.</p>
                    <p>
                      {" "}
                      - Post one or more blog posts to give relevant information
                      to your customers.
                    </p>
                    <p> - Check for broken links.</p>
                  </div>
                </div>
                <div>
                  <h3 className="text-xl text-red-500">Monthly Tasks</h3>
                  <div className="pt-4 text-sm">
                    <p> - Test your website loading speed.</p>
                    <p>
                      {" "}
                      - Analyze your security scans and if any issues, make sure
                      they’re resolved.
                    </p>
                    <p> - Analyze website statistics.</p>
                    <p> - Check your local search visibility.</p>
                  </div>
                </div>
                <div>
                  <h3 className="text-xl text-red-500">Quarterly Tasks</h3>
                  <div className="pt-4 text-sm">
                    <p>
                      {" "}
                      - Review your website and check if there’s room for
                      improvement.
                    </p>
                    <p> - Update your web graphics and photos.</p>
                    <p>
                      {" "}
                      - Check your website content and make sure it’s
                      up-to-date.
                    </p>
                    <p> - Update your meta descriptions and meta titles.</p>
                    <p>
                      {" "}
                      - Make sure that the website is mobile-friendly and it
                      displays nicely.
                    </p>
                    <p>
                      {" "}
                      - Test and update popups, forms, and calls to action.
                    </p>
                    <p>
                      {" "}
                      - Check to see if anything needs to be changed or updated
                    </p>
                    <p>
                      {" "}
                      - Restore the most recent backup to check your backup
                      health.
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-xl text-red-500">Annual Tasks</h3>
                  <div className="pt-4 text-sm">
                    <p>
                      {" "}
                      - Renew your website domain name, hosting, and others
                      accounts.
                    </p>
                    <p> - Consider updating your website design.</p>
                    <p>
                      {" "}
                      - Review each page of the site to make sure its content is
                      accurate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mt-8 md:w-3/4 md:-top-36 md:left-10">
              <ContactForm />
            </div>
          </div>

          {/* NEXT SECTION  */}

          <div className=" my-16 text-xl text-center">
            <p>
              Go with us and you can benefit from our experience, with anamazing
              range of skills, deep vertical industries
            </p>
            <p>
              expertise, and excellence in top technologies to ensure your
              website is in a healthy and relevant condition.
            </p>
          </div>

          {/* TECH COMPONENT  */}
          <Tech />
        </div>
      </Container>
    </Layout>
  );
}

export default SupportMaintenance;
